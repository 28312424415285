import support from "../img/support.svg"
import careers from "../img/star-circle.svg"
import person from "../img/person.svg"

export const navLinks = [
    {
        title: "Home",
        url: '/'
    },
    {
        title: "School Directory",
        url: '/school-directory'
    },
    // {
    //     title: "Contact",
    //     url: '/contact'
    // },
    {
        title: "For Schools",
        url: '/for-schools'
    },
    {
        title: "WhatsApp & Ai Chatbot",
        url: "whatsapp-ai-chatbot"
    }
]

export const category = [
    {
        name: "Preschool",
        description: "Children aged from 3 months to 6 years",
        url: "pre-school"
    },
    {
        name: "Primary",
        description: "Schools with grade 1 to grade 7",
        url: "primary"
    },
    {
        name: "High School/College",
        description: "Schools with grade 8 to grade 12",
        url: "high-school"
    },
    {
        name: "Combined",
        description: "Schools with grade R to grade 12",
        url: "combined"
    }
]

export const contactUs = [
    {
        name: "Support",
        email: 'support@easyapplications.co.za',
        url: support,
        description: "Contact us if you are experiencing technical issues on our site."
    },
    {
        name: "Careers",
        email: 'careers@easyapplications.co.za',
        url: careers,
        description: "Looking for a job? Send us your CV and Motivation Letter."
    },
    {
        name: "Partnerships",
        email: 'schools@easyapplications.co.za',
        url: person,
        description: "Contact us to learn more on how to become a partner school."
    }
]

export const mySchoolLinks = [
    {
        title: "School Information",
        url: 'about'
    },
    {
        title: "Application Form",
        url: "form"
    },
    {
        title: "Requirements",
        url: "documents"
    },
    {
        title: "Review & Submit",
        url: "review"
    }
]

export const schoolDashboardLinks = [
    {
        title: "Dashboard",
        link: "dashboard"
    },
    {
        title: "Applicants",
        link: "applicants"
    },
    {
        title: "Inquiries",
        link: "inquiries"
    },
    {
        title: "Settings",
        link: "settings"
    },
    {
        title: "Help & Support",
        link: "help-and-support"
    },
    {
        title: "Logout"
    }
]

export const courses = [
    {
        courseName: "Structural Analysis I",
        courseCode: "CIVN3026",
        period: "Semester 1",
        status: "Completed",
        enrollment: "Student",
        link: "structural-analysis-i"
    },
    {
        courseName: "Introduction to Environmental Engineering",
        courseCode: "CIVN2013",
        period: "Semester 2",
        status: "Not Started",
        enrollment: "Student",
        link: "introduction-to-environmental-engineering"
    },
    {
        courseName: "Hydraulics Engineering",
        courseCode: "CIVN3017",
        period: "Full Year",
        status: "In Progress",
        enrollment: "Student",
        link: "hydraulics-engineering"
    }
]
