 import "firebase/compat/storage"
import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/analytics"

const firebaseApp = firebase.initializeApp({
    apiKey: "AIzaSyDOYoHQKPnhmG12U_e-pjo9NXthCGYXBgo",
    authDomain: "narrow-d93a5.firebaseapp.com",
    databaseURL: "https://narrow-d93a5-default-rtdb.firebaseio.com",
    projectId: "narrow-d93a5",
    storageBucket: "narrow-d93a5.appspot.com",
    messagingSenderId: "225405093887",
    appId: "1:225405093887:web:633235b2fdfd3b4de3c9ca",
    measurementId: "G-XN6NKBQRHZ"
})



const auth = firebaseApp.auth()
const db = firebaseApp.database()
const analytics = firebaseApp.analytics()
const storage = firebaseApp.storage()

analytics.setAnalyticsCollectionEnabled(true)

export { auth, db, storage }