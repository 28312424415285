import React from "react";
import SEO from "../seo/SEO";
import Hero from "../components/Hero";

import star from "../img/star.svg"

function HomePage() {

    return (
        <div  className="page-wrapper">
            <SEO title="Apply to schools with Narow | Your future starts here"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://www.easyapplications.web.app" />
            <Hero />
            <div className="section top-0 overflow-hidden">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-2-columns gap-row-24px">
                        <div className="card free-product-card">
                            <div className="z-index-1">
                                <div className="mg-bottom-18px">
                                    <div className="circle _48px blue">
                                        <div className="line-rounded-icon arrow-up"></div>
                                    </div>
                                </div>
                                <h2 className="display-3 mg-bottom-8px">Start your Child's Application!</h2>
                                <div className="inner-container">
                                    <p className="paragraph-small mg-bottom-24px">
                                        Schools registration and application is still open. Start applying to as many schools as possible.
                                    </p>
                                </div>
                            </div>
                            <div className="z-index-1 width-100-mbp">
                                <a href="https://easyapplications.co.za" className="btn-primary small w-button">
                                    Start application now<span className="line-rounded-icon link-icon-right mg-left-4px"></span>
                                </a>
                            </div>
                            <div className="floating-item circle-floating v1"></div>
                        </div>
                        <div className="card featured-product">
                            <div className="z-index-1">
                                <div className="mg-bottom-18px">
                                    <div className="circle _48px white">
                                        <img src={star} alt=""/>
                                    </div>
                                </div>
                                <h2 className="display-3 mg-bottom-8px color-neutral-100">
                                    List your School on our Directory!
                                </h2>
                                <div className="inner-container">
                                    <p className="mg-bottom-24 color-neutral-100 paragraph-small">
                                        Start receiving applications from parents while saving up to 40% in advertising costs.
                                    </p>
                                </div>
                            </div>
                            <div className="z-index-1 width-100-mbp">
                                <a href="https://narow.co.za" className="btn-primary small black w-button">
                                    Become a member school <span className="line-rounded-icon link-icon-right mg-left-4px"></span>
                                </a>
                            </div>
                            <div className="floating-item circle-floating white v1"></div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="section top-0 overflow-hidden">*/}
            {/*    <div className="container-default w-container">*/}
            {/*        <div>*/}
            {/*            <div data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb2"*/}
            {/*                 className="flex-horizontal mg-bottom-40px">*/}
            {/*                <h2 className="display-3 mg-bottom-0">Collections curated by our team</h2>*/}
            {/*            </div>*/}
            {/*            <div data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb5"*/}
            {/*                 className="w-layout-grid grid-3-columns _1-col-tablet">*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb6"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/development"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            alt="Apps For Developers - Aggregator X Webflow Template" className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for developers</h3>*/}
            {/*                        <p className="paragraph-small mg-bottom-20px">Lorem ipsum dolor sit amet consectetur*/}
            {/*                            adipiscing elit duis blandit viverra.</p>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386ec4"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/marketing"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            alt="Apps For Marketers - Aggregator X Webflow Template" className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for marketers</h3>*/}
            {/*                        <p className="paragraph-small mg-bottom-20px">Id sit adipiscing amet amet sem*/}
            {/*                            volutpat turpis posuere turpis curabitur parturient.</p>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386ed2"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/design"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            alt="Apps For Designers - Aggregator X Webflow Template" className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for designers</h3>*/}
            {/*                        <div className="inner-container _280px _100-tablet">*/}
            {/*                            <p className="paragraph-small mg-bottom-20px">Tempus a sit convallis amet*/}
            {/*                                ultrices eget eleifend tincidunt cras ultrices egestas.</p>*/}
            {/*                        </div>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className="section bg-neutral-200 overflow-hidden z-index-1">*/}
            {/*    <div className="container-default w-container">*/}
            {/*        <div className="w-layout-grid grid-2-columns articles-resources-grid">*/}
            {/*            <div id="w-node-_8bbc6ded-6d82-1c08-563e-13e96b613a63-2574232c"*/}
            {/*                 className="article-resources---left-col">*/}
            {/*                <div className="slider-left-side-cover"></div>*/}
            {/*                <div className="position-relative z-index-4">*/}
            {/*                    <div data-w-id="06104d18-6e1e-f9cf-4e55-08c934055c26"*/}
            {/*                         className="inner-container _298px _100-tablet">*/}
            {/*                        <h2 className="display-3 mg-bottom-12px">Articles &amp;resources</h2>*/}
            {/*                        <div className="inner-container _284px _100-tablet">*/}
            {/*                            <p className="paragraph-small mg-bottom-24px mg-bottom-16px-tablet">Lorem ipsum*/}
            {/*                                dolor sit amet consectetur adipiscing elit duis blandit viverra feugiat sit*/}
            {/*                                quis tristique.</p>*/}
            {/*                        </div>*/}
            {/*                        <a href="/blog" className="btn-secondary small w-button">Browse all articles</a>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div id="w-node-_6db8ead3-9595-5032-ac3b-96a7c5313541-2574232c"*/}
            {/*                 className="article-resources---right-col">*/}
            {/*                <div data-delay="4000" data-animation="slide" className="slider-wrapper w-slider"*/}
            {/*                     data-autoplay="false" data-easing="ease"  data-hide-arrows="true"*/}
            {/*                     data-disable-swipe="false" data-w-id="1e50d272-2b7a-9825-34e7-cb8ed0594a4e"*/}
            {/*                     data-autoplay-limit="0" data-nav-spacing="3" data-duration="500" data-infinite="true">*/}
            {/*                    <div className="slider-mask article-resource-slide-mask w-slider-mask">*/}
            {/*                        <div className="slide-item-mg w-slide">*/}
            {/*                            <div className="w-dyn-list">*/}
            {/*                                <div role="list" className="w-dyn-items">*/}
            {/*                                    <div role="listitem" className="w-dyn-item">*/}
            {/*                                        <a data-w-id="ef92c241-06bc-3db9-1bba-8c9bce478683"*/}
            {/*                                           href="/blog/how-to-build-and-launch-your-first-saas-application"*/}
            {/*                                           className="blog-link-v1 w-inline-block">*/}
            {/*                                            <div>*/}
            {/*                                                <img*/}
            {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c088d461f8ba9f0e462579_how-to-build-and-launch-your-first-saas-application-aggregator-x-webflow-template.jpg"*/}
            {/*                                                    alt="How to build and launch your first SaaS application "*/}
            {/*                                                    sizes="(max-width: 479px) 92vw, 388px"*/}
            {/*                                                    srcSet="https://assets.website-files.com/63bed0273cfe5e611e742359/63c088d461f8ba9f0e462579_how-to-build-and-launch-your-first-saas-application-aggregator-x-webflow-template-p-500.jpg 500w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c088d461f8ba9f0e462579_how-to-build-and-launch-your-first-saas-application-aggregator-x-webflow-template-p-800.jpg 800w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c088d461f8ba9f0e462579_how-to-build-and-launch-your-first-saas-application-aggregator-x-webflow-template.jpg 1440w"*/}
            {/*                                                    className="_w-h-100"/>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="blog-content">*/}
            {/*                                                <div className="mg-bottom-24px">*/}
            {/*                                                    <div className="badge-primary">*/}
            {/*                                                        <div className="flex-horizontal start gap-6px">*/}
            {/*                                                            <img*/}
            {/*                                                                src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c1796488c67f733ae4fa4c_marketing-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                                alt="Development"/>*/}
            {/*                                                            <div>Development</div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                <h3 className="display-4 mg-bottom-16px">How to build and*/}
            {/*                                                    launch your first SaaS application </h3>*/}
            {/*                                                <div className="blog-information">*/}
            {/*                                                    <div className="text-200 color-neutral-600">Jan 12,*/}
            {/*                                                        2023*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className="blog-read-wrapper">*/}
            {/*                                                        <img*/}
            {/*                                                            src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c08922ab80d475d60c08fa_clock-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                            alt="12 min read"*/}
            {/*                                                            className="mg-right-4px blog-read-icon"/>*/}
            {/*                                                        <div className="text-200 color-neutral-600">12 min*/}
            {/*                                                            read*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="slide-item-mg w-slide">*/}
            {/*                            <div className="w-dyn-list">*/}
            {/*                                <div role="list" className="w-dyn-items">*/}
            {/*                                    <div role="listitem" className="w-dyn-item">*/}
            {/*                                        <a data-w-id="3588b461-0597-0cd1-0527-23b20f995f13"*/}
            {/*                                           href="/blog/10-product-design-tips-to-make-users-fall-in-love-with-your-app"*/}
            {/*                                           className="blog-link-v1 w-inline-block">*/}
            {/*                                            <div>*/}
            {/*                                                <img*/}
            {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c088b6240187742c811c70_ten-product-design-tips-to-make-users-fall-in-love-with-your-app-aggregator-x-webflow-template.jpg"*/}
            {/*                                                    alt="10 product design tips to make users fall in love with your app"*/}
            {/*                                                    sizes="(max-width: 479px) 92vw, 388px"*/}
            {/*                                                    srcSet="https://assets.website-files.com/63bed0273cfe5e611e742359/63c088b6240187742c811c70_ten-product-design-tips-to-make-users-fall-in-love-with-your-app-aggregator-x-webflow-template-p-500.jpg 500w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c088b6240187742c811c70_ten-product-design-tips-to-make-users-fall-in-love-with-your-app-aggregator-x-webflow-template-p-800.jpg 800w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c088b6240187742c811c70_ten-product-design-tips-to-make-users-fall-in-love-with-your-app-aggregator-x-webflow-template.jpg 1440w"*/}
            {/*                                                    className="_w-h-100"/>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="blog-content">*/}
            {/*                                                <div className="mg-bottom-24px">*/}
            {/*                                                    <div className="badge-primary">*/}
            {/*                                                        <div className="flex-horizontal start gap-6px">*/}
            {/*                                                            <img*/}
            {/*                                                                src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c989784b9a2a31fbcf48dd_development-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                                alt="Marketing"/>*/}
            {/*                                                            <div>Marketing</div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                <h3 className="display-4 mg-bottom-16px">10 product design*/}
            {/*                                                    tips to make users fall in love with your app</h3>*/}
            {/*                                                <div className="blog-information">*/}
            {/*                                                    <div className="text-200 color-neutral-600">Jan 12,*/}
            {/*                                                        2023*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className="blog-read-wrapper">*/}
            {/*                                                        <img*/}
            {/*                                                            src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c08922ab80d475d60c08fa_clock-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                            alt="8 min read"*/}
            {/*                                                            className="mg-right-4px blog-read-icon"/>*/}
            {/*                                                        <div className="text-200 color-neutral-600">8 min*/}
            {/*                                                            read*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="slide-item-mg w-slide">*/}
            {/*                            <div className="w-dyn-list">*/}
            {/*                                <div role="list" className="w-dyn-items">*/}
            {/*                                    <div role="listitem" className="w-dyn-item">*/}
            {/*                                        <a data-w-id="f2d0c3ab-ae85-ea1c-e522-c58bb05c0825"*/}
            {/*                                           href="/blog/the-best-methodology-to-design-innovative-products"*/}
            {/*                                           className="blog-link-v1 w-inline-block">*/}
            {/*                                            <div>*/}
            {/*                                                <img*/}
            {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c0889243acc6be3783ab8a_the-best-methodology-to-design-innovative-products-aggregator-x-webflow-template.jpg"*/}
            {/*                                                    alt="The best methodology to design innovative products"*/}
            {/*                                                    sizes="(max-width: 479px) 92vw, 388px"*/}
            {/*                                                    srcSet="https://assets.website-files.com/63bed0273cfe5e611e742359/63c0889243acc6be3783ab8a_the-best-methodology-to-design-innovative-products-aggregator-x-webflow-template-p-500.jpg 500w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0889243acc6be3783ab8a_the-best-methodology-to-design-innovative-products-aggregator-x-webflow-template-p-800.jpg 800w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0889243acc6be3783ab8a_the-best-methodology-to-design-innovative-products-aggregator-x-webflow-template-p-1080.jpg 1080w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0889243acc6be3783ab8a_the-best-methodology-to-design-innovative-products-aggregator-x-webflow-template.jpg 1440w"*/}
            {/*                                                    className="_w-h-100"/>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="blog-content">*/}
            {/*                                                <div className="mg-bottom-24px">*/}
            {/*                                                    <div className="badge-primary">*/}
            {/*                                                        <div className="flex-horizontal start gap-6px">*/}
            {/*                                                            <img*/}
            {/*                                                                src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c085293fe8bd2613747416_design-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                                alt="Design"/>*/}
            {/*                                                            <div>Design</div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                <h3 className="display-4 mg-bottom-16px">The best*/}
            {/*                                                    methodology to design innovative products</h3>*/}
            {/*                                                <div className="blog-information">*/}
            {/*                                                    <div className="text-200 color-neutral-600">Jan 12,*/}
            {/*                                                        2023*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className="blog-read-wrapper">*/}
            {/*                                                        <img*/}
            {/*                                                            src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c08922ab80d475d60c08fa_clock-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                            alt="10 min read"*/}
            {/*                                                            className="mg-right-4px blog-read-icon"/>*/}
            {/*                                                        <div className="text-200 color-neutral-600">10 min*/}
            {/*                                                            read*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="slide-item-mg w-slide">*/}
            {/*                            <div className="w-dyn-list">*/}
            {/*                                <div role="list" className="w-dyn-items">*/}
            {/*                                    <div role="listitem" className="w-dyn-item">*/}
            {/*                                        <a data-w-id="28bd77c7-cc7d-f03b-550a-4dacecdca99d"*/}
            {/*                                           href="/blog/20-apps-for-productivity-and-business-management"*/}
            {/*                                           className="blog-link-v1 w-inline-block">*/}
            {/*                                            <div>*/}
            {/*                                                <img*/}
            {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c088745da78809473b243f_twenty-apps-for-productivity-and-business-management-aggregator-x-webflow-template.jpg"*/}
            {/*                                                    alt="20 apps for productivity and business management"*/}
            {/*                                                    sizes="(max-width: 479px) 92vw, 388px"*/}
            {/*                                                    srcSet="https://assets.website-files.com/63bed0273cfe5e611e742359/63c088745da78809473b243f_twenty-apps-for-productivity-and-business-management-aggregator-x-webflow-template-p-500.jpg 500w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c088745da78809473b243f_twenty-apps-for-productivity-and-business-management-aggregator-x-webflow-template-p-800.jpg 800w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c088745da78809473b243f_twenty-apps-for-productivity-and-business-management-aggregator-x-webflow-template.jpg 1440w"*/}
            {/*                                                    className="_w-h-100"/>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="blog-content">*/}
            {/*                                                <div className="mg-bottom-24px">*/}
            {/*                                                    <div className="badge-primary">*/}
            {/*                                                        <div className="flex-horizontal start gap-6px">*/}
            {/*                                                            <img*/}
            {/*                                                                src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c989784b9a2a31fbcf48dd_development-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                                alt="Marketing"/>*/}
            {/*                                                            <div>Marketing</div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                <h3 className="display-4 mg-bottom-16px">20 apps for*/}
            {/*                                                    productivity and business management</h3>*/}
            {/*                                                <div className="blog-information">*/}
            {/*                                                    <div className="text-200 color-neutral-600">Jan 12,*/}
            {/*                                                        2023*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className="blog-read-wrapper">*/}
            {/*                                                        <img*/}
            {/*                                                            src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c08922ab80d475d60c08fa_clock-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                            alt="8 min read"*/}
            {/*                                                            className="mg-right-4px blog-read-icon"/>*/}
            {/*                                                        <div className="text-200 color-neutral-600">8 min*/}
            {/*                                                            read*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="slide-item-mg w-slide">*/}
            {/*                            <div className="w-dyn-list">*/}
            {/*                                <div role="list" className="w-dyn-items">*/}
            {/*                                    <div role="listitem" className="w-dyn-item">*/}
            {/*                                        <a data-w-id="57a17456-cb99-8579-d410-4c806044695f"*/}
            {/*                                           href="/blog/how-to-get-funding-for-your-first-saas-product"*/}
            {/*                                           className="blog-link-v1 w-inline-block">*/}
            {/*                                            <div>*/}
            {/*                                                <img*/}
            {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c0883ba5cbecb77196ad98_how-to-get-funding-for-your-first-saas-product-aggregator-x-webflow-template.jpg"*/}
            {/*                                                    alt="How to get funding for your first SaaS product"*/}
            {/*                                                    sizes="(max-width: 479px) 92vw, 388px"*/}
            {/*                                                    srcSet="https://assets.website-files.com/63bed0273cfe5e611e742359/63c0883ba5cbecb77196ad98_how-to-get-funding-for-your-first-saas-product-aggregator-x-webflow-template-p-500.jpg 500w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0883ba5cbecb77196ad98_how-to-get-funding-for-your-first-saas-product-aggregator-x-webflow-template-p-800.jpg 800w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0883ba5cbecb77196ad98_how-to-get-funding-for-your-first-saas-product-aggregator-x-webflow-template-p-1080.jpg 1080w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0883ba5cbecb77196ad98_how-to-get-funding-for-your-first-saas-product-aggregator-x-webflow-template.jpg 1440w"*/}
            {/*                                                    className="_w-h-100"/>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="blog-content">*/}
            {/*                                                <div className="mg-bottom-24px">*/}
            {/*                                                    <div className="badge-primary">*/}
            {/*                                                        <div className="flex-horizontal start gap-6px">*/}
            {/*                                                            <img*/}
            {/*                                                                src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c1796488c67f733ae4fa4c_marketing-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                                alt="Development"/>*/}
            {/*                                                            <div>Development</div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                <h3 className="display-4 mg-bottom-16px">How to get funding*/}
            {/*                                                    for your first SaaS product</h3>*/}
            {/*                                                <div className="blog-information">*/}
            {/*                                                    <div className="text-200 color-neutral-600">Jan 12,*/}
            {/*                                                        2023*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className="blog-read-wrapper">*/}
            {/*                                                        <img*/}
            {/*                                                            src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c08922ab80d475d60c08fa_clock-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                            alt="16 min read"*/}
            {/*                                                            className="mg-right-4px blog-read-icon"/>*/}
            {/*                                                        <div className="text-200 color-neutral-600">16 min*/}
            {/*                                                            read*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="slide-item-mg w-slide">*/}
            {/*                            <div className="w-dyn-list">*/}
            {/*                                <div role="list" className="w-dyn-items">*/}
            {/*                                    <div role="listitem" className="w-dyn-item">*/}
            {/*                                        <a data-w-id="94664b10-cba9-c2e0-0296-19b1d1377deb"*/}
            {/*                                           href="/blog/4-step-guide-to-a-successful-aggregator-product-launch-2"*/}
            {/*                                           className="blog-link-v1 w-inline-block">*/}
            {/*                                            <div>*/}
            {/*                                                <img*/}
            {/*                                                    src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c0864b64339f561925d30f_four-step-guide-to-a-successful-aggregator-product-launch-aggregator-x-webflow-template.jpg"*/}
            {/*                                                    alt="4 step guide to a successful Aggregator product launch "*/}
            {/*                                                    sizes="(max-width: 479px) 92vw, 388px"*/}
            {/*                                                    srcSet="https://assets.website-files.com/63bed0273cfe5e611e742359/63c0864b64339f561925d30f_four-step-guide-to-a-successful-aggregator-product-launch-aggregator-x-webflow-template-p-500.jpg 500w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0864b64339f561925d30f_four-step-guide-to-a-successful-aggregator-product-launch-aggregator-x-webflow-template-p-800.jpg 800w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0864b64339f561925d30f_four-step-guide-to-a-successful-aggregator-product-launch-aggregator-x-webflow-template-p-1080.jpg 1080w, https://assets.website-files.com/63bed0273cfe5e611e742359/63c0864b64339f561925d30f_four-step-guide-to-a-successful-aggregator-product-launch-aggregator-x-webflow-template.jpg 1440w"*/}
            {/*                                                    className="_w-h-100"/>*/}
            {/*                                            </div>*/}
            {/*                                            <div className="blog-content">*/}
            {/*                                                <div className="mg-bottom-24px">*/}
            {/*                                                    <div className="badge-primary">*/}
            {/*                                                        <div className="flex-horizontal start gap-6px">*/}
            {/*                                                            <img*/}
            {/*                                                                src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c085293fe8bd2613747416_design-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                                alt="Design"/>*/}
            {/*                                                            <div>Design</div>*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                                <h3 className="display-4 mg-bottom-16px">4 step guide to a*/}
            {/*                                                    successful Aggregator product launch </h3>*/}
            {/*                                                <div className="blog-information">*/}
            {/*                                                    <div className="text-200 color-neutral-600">Jan 12,*/}
            {/*                                                        2023*/}
            {/*                                                    </div>*/}
            {/*                                                    <div className="blog-read-wrapper">*/}
            {/*                                                        <img*/}
            {/*                                                            src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c08922ab80d475d60c08fa_clock-icon-aggregator-x-webflow-template.svg"*/}
            {/*                                                            alt="6 min read"*/}
            {/*                                                            className="mg-right-4px blog-read-icon"/>*/}
            {/*                                                        <div className="text-200 color-neutral-600">6 min*/}
            {/*                                                            read*/}
            {/*                                                        </div>*/}
            {/*                                                    </div>*/}
            {/*                                                </div>*/}
            {/*                                            </div>*/}
            {/*                                        </a>*/}
            {/*                                    </div>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div className="btn-circle-secondary product-slider-arrow left-v2 w-slider-arrow-left">*/}
            {/*                        <div className="line-rounded-icon"></div>*/}
            {/*                    </div>*/}
            {/*                    <div*/}
            {/*                        className="btn-circle-secondary product-slider-arrow right-v2 w-slider-arrow-right">*/}
            {/*                        <div className="line-rounded-icon"></div>*/}
            {/*                    </div>*/}
            {/*                    <div className="hidden-on-desktop w-slider-nav w-round"></div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default HomePage