import React, {useState} from "react";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import {school} from "../data/schoolsData";

function ViewRequirements({ open, setOpen, schoolName }) {
    const [maxWidth] = useState("md")

    return (
        <Dialog fullWidth={true} maxWidth={maxWidth} open={open}>
            <DialogTitle>Application Requirements</DialogTitle>
            <DialogContent>
                <ul>
                    {school.filter((item) => item.name === schoolName).map((schoolData) => (
                        <div>
                            {schoolData.requirements.map((requiredItem) => (
                                <li>{requiredItem.name}</li>
                            ))}
                        </div>
                    ))}
                </ul>
            </DialogContent>
            <DialogActions>
                <button className="btn-primary" onClick={() => setOpen(false)}>Cancel</button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewRequirements