import React from "react";
import arrowUp from "../img/arrow-up.svg"

function ApplyNowCard() {
    return (
        <div className="card submit-card">
            <div className="mg-bottom-24px">
                <div className="circle _48px blue">
                    <img src={arrowUp} alt=""/>
                </div>
            </div>
            <div className="display-4 mg-bottom-8px">Start applying now</div>
            <p className="mg-bottom-24px paragraph-small">
                Applying to schools for your child has never been easy!
            </p>
            <a href="https://easyapplications.co.za" className="btn-primary small black w-button">
                Go to Student Portal<span className="line-rounded-icon link-icon-right mg-left-4px"></span>
            </a>
        </div>
    )
}

export default ApplyNowCard