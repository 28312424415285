import React, {useState} from "react";
import {school} from "../data/schoolsData";
import FeaturedSchool from "../components/FeaturedSchool";
import Schools from "../components/Schools";
import {category} from "../utils/constants";
import ApplyNowCard from "../components/ApplyNowCard";
import schoolData from "../data/private_college.json"
import SEO from "../seo/SEO";
import Hero from "../components/Hero";

import briefcase from "../img/briefcase.svg"

function SchoolsPage() {
    const [number, setNumber] = useState(1)

    const postPerPage = 100
    const pageNumber = []

    const lastPost = number * postPerPage
    const firstPost = lastPost - postPerPage
    const currentPost = schoolData.school.slice(firstPost, lastPost)

    for (let i = 1; i <= Math.ceil(schoolData.school.length / postPerPage); i++) {
        pageNumber.push(i)
    }

    const changePage = (pageNumber) => {
        setNumber(pageNumber)
    }

    return (
        <div  className="page-wrapper">
            <SEO title="Explore schools using Narow | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://www.easyapplications.web.app" />
            <div className="section hero product-hero">
                <div className="container-default w-container">
                    <div className="product-board-wrapper">
                        <div className="split-content product-board-content">
                            {/*<div className="featured-products-wrapper">*/}
                            {/*    <div className="mg-bottom-32px">*/}
                            {/*        <h1 className="display-3 featuted-product-title">Featured schools</h1>*/}
                            {/*    </div>*/}
                            {/*    <div className="w-dyn-list">*/}
                            {/*        <div role="list" className="grid-1-column product-grid-v1 w-dyn-items">*/}
                            {/*            {school.map((item) => (*/}
                            {/*                <FeaturedSchool schoolName={item.name}*/}
                            {/*                                physicalAddress={item.physical_address}*/}
                            {/*                                schoolPhase={item.type} />*/}
                            {/*            ))}*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div className="products-wrapper">
                                <div className="mg-bottom-32px mg-bottom-40px---mbp">
                                    <div className="flex-title">
                                        <h2 className="display-3 mg-bottom-0">All private schools</h2>
                                        <a href="/schools" aria-current="page"
                                           className="btn-secondary small w-button w--current">Browse all schools</a>
                                    </div>
                                </div>
                                <div className="w-dyn-list">
                                    <div role="list" className="grid-1-column product-grid-v1 w-dyn-items">
                                        {currentPost.map((item) => (
                                            <Schools schoolName={item.name} physicalAddress={item.physical_address} schoolPhase={item.type} />
                                        ))}
                                    </div>
                                    <div className="w-pagination-wrapper mg-top-40px">
                                        <button className="w-pagination-previous btn-secondary previous-btn small" onClick={() => setNumber(number - 1)}>
                                            Previous
                                        </button>
                                        {pageNumber.map((item) => {
                                            if (item === number) {
                                                return (
                                                    <button disabled className="btn-primary small mg-left-4px mg-right-4px">
                                                        {item}
                                                    </button>
                                                )
                                            } else {
                                                return (
                                                    <button onClick={() => changePage(item)} className="btn-secondary small mg-left-4px mg-right-4px">
                                                        {item}
                                                    </button>
                                                )
                                            }
                                        })}
                                        <button className="w-pagination-next btn-primary next-btn small" onClick={() => setNumber(number + 1)}>
                                            Next
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="split-content product-sidebar">
                            <div className="position-sticky top-24px static---tablet">
                                <h2 className="heading-h5-size mg-bottom-20px">Filter by category</h2>
                                <div className="mg-bottom-32px">
                                    <div className="inner-container _312px _100-tablet">
                                        <div className="categories-card-wrapper">
                                            <div className="w-dyn-list">
                                                <div role="list" className="grid-1-column categories-grid w-dyn-items">
                                                    {category.map((item) => (
                                                        <div role="listitem" className="w-dyn-item">
                                                            <a href={`/schools/${item.url}`} className="category---link-wrapper w-inline-block">
                                                                <div className="category---description-wrapper">
                                                                    <div>
                                                                        <img src={briefcase} alt="Business" className="_w-h-20px"/>
                                                                    </div>
                                                                    <div className="category---description">
                                                                        <div className="category---title">{item.name}</div>
                                                                        <div className="category---short-description">
                                                                            {item.description}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="categories---arrow-right">
                                                                    <div className="line-rounded-icon link-icon-right"></div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ApplyNowCard />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="section bg-neutral-200 overflow-hidden">*/}
            {/*    <div className="container-default w-container">*/}
            {/*        <div>*/}
            {/*            <div data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb2"*/}
            {/*                 className="flex-horizontal mg-bottom-40px">*/}
            {/*                <h2 className="display-3 mg-bottom-0">Collections curated by our team</h2>*/}
            {/*            </div>*/}
            {/*            <div data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb5"*/}
            {/*                 className="w-layout-grid grid-3-columns _1-col-tablet">*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386eb6"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/development"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5c67446467c450bf9f806_apps-for-developers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            alt="Apps For Developers - Aggregator X Webflow Template" className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for developers</h3>*/}
            {/*                        <p className="paragraph-small mg-bottom-20px">Lorem ipsum dolor sit amet consectetur*/}
            {/*                            adipiscing elit duis blandit viverra</p>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386ec4"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/marketing"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cab897f0b6738bd12ae4_apps-for-marketers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            alt="Apps For Marketers - Aggregator X Webflow Template" className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for marketers</h3>*/}
            {/*                        <p className="paragraph-small mg-bottom-20px">Id sit adipiscing amet amet sem*/}
            {/*                            volutpat turpis posuere turpis curabitur parturient.</p>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*                <a data-w-id="ee8d6238-05d6-8788-93ac-5ffa4f386ed2"*/}
            {/*                   href="https://aggregatortemplate.webflow.io/product-categories/design"*/}
            {/*                   className="card card-link-image-top flex w-inline-block">*/}
            {/*                    <div>*/}
            {/*                        <img*/}
            {/*                            src="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template.png"*/}
            {/*                            srcSet="https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template-p-500.png 500w, https://assets.website-files.com/63bed0273cfe5e3f80742329/63c5cae597f0b6b265d12d13_apps-for-designers-aggregator-x-webflow-template.png 776w"*/}
            {/*                            sizes="(max-width: 479px) 91vw, (max-width: 767px) 93vw, (max-width: 991px) 95vw, (max-width: 1439px) 30vw, 386px"*/}
            {/*                            alt="Apps For Designers - Aggregator X Webflow Template" className="_w-h-100"/>*/}
            {/*                    </div>*/}
            {/*                    <div className="card-description">*/}
            {/*                        <h3 className="display-4">Apps for designers</h3>*/}
            {/*                        <div className="inner-container _280px _100-tablet">*/}
            {/*                            <p className="paragraph-small mg-bottom-20px">Tempus a sit convallis amet*/}
            {/*                                ultrices eget eleifend tincidunt cras ultrices egestas.</p>*/}
            {/*                        </div>*/}
            {/*                        <div className="collection-our-team-link">*/}
            {/*                            <div>Browse apps</div>*/}
            {/*                            <div className="line-rounded-icon"></div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </a>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default SchoolsPage