import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {onValue, ref} from "firebase/database";
import ViewRequirements from "./ViewRequirements";

import featuredIcon from "../img/featured-star-icon.svg"

function FeaturedSchool({ schoolName, physicalAddress, schoolPhase, accreditationNo }) {
    const [open, setOpen] = useState(false)
    const [mySchools, setMySchools] = useState([])

    const history = useNavigate()

    const showDialog = () => {
        setOpen(true)
    }

    return (
        <div role="listitem" className="w-dyn-item">
            <div className="card product-card-v1 featured">
                <div className="product---description">
                    {/*<div>*/}
                    {/*    <img*/}
                    {/*        src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c82fd5f0b9d119f5e3d6c0_webtech-featured-image-aggregator-x-webflow-template.svg"*/}
                    {/*        alt="Webtech" className="avatar-circle _04"/>*/}
                    {/*</div>*/}
                    <div>
                        <div className="product---name">{schoolName}</div>
                        <div>
                            <p className="paragraph-small medium mg-bottom-10px">
                                {physicalAddress}
                            </p>
                        </div>
                        <div className="product---tag-wrapper">
                            <div className="product---featured-icon">
                                <img src={featuredIcon} loading="lazy" alt="" className="_w-h-26px"/>
                            </div>
                            <div className="badge-primary small">{schoolPhase}</div>
                        </div>
                    </div>
                </div>
                <div className="product-card---links-wrapper">
                    <button className="btn-secondary small" onClick={showDialog}>
                        Requirements
                    </button>
                    <ViewRequirements open={open} setOpen={setOpen} schoolName={schoolName} />
                    <a href="https://apply.easyapplications.co.za" className="btn-primary btn-visit w-button">
                        Apply Now<span className="line-rounded-icon link-icon-right"></span>
                    </a>
                </div>
            </div>
        </div>
    )
}
export default FeaturedSchool