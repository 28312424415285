import React, {useState} from "react";
import {contactUs} from "../utils/constants";
import SEO from "../seo/SEO";
import Hero from "../components/Hero";
import * as emailjs from "@emailjs/browser";
import {db} from "../firebase";

import envelope from "../img/envelope.svg"
import phoneCall from "../img/phone-call.svg"

function ContactPage() {
    const [contactInfo, setContactInfo] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
        location: '',
        message: ''

    })

    const handleChange = name => event => {
        event.preventDefault()

        const value = event.target.value
        setContactInfo({ ...contactInfo, [name]: value })
    }

    const sendMessage = (e) => {
        e.preventDefault()

        const postId = db.ref().push().key

        const details = {
            first_name: contactInfo.fullName,
            surname: contactInfo.fullName,
            from_email: contactInfo.email,
            postId: postId,
            title: "none",
            phone_number: contactInfo.phoneNumber,
            school_name: "none",
            message: contactInfo.message,
        }

        emailjs.send("service_1yl0epd", "template_y5eh80f", details, "OFApr8t8B2N1jy0Wg")
            .then((r) => {
            });

        db.ref().child(`narrow/messages/${postId}`).set({
            fullName: contactInfo.fullName,
            phoneNumber: contactInfo.phoneNumber,
            email: contactInfo.email,
            message: contactInfo.message,
            postId: postId

        }).then(() => {
            alert('Message sent successfully! We will be in contact as soon as possible')

            setContactInfo({
                fullName: '',
                email: '',
                phoneNumber: '',
                location: '',
                message: ''
            })


        }, (error) => {
            alert(error.message)

        })

    }


    return (
        <div  className="page-wrapper">
            <SEO title="Leave us a message | Narow"
                 description="Narow is a central application system of South African pre-schools, primary and high schools."
                 canonicalURL="https://www.easyapplications.web.app" />
            <div className="section hero contact">
                <div className="container-default w-container">
                    <div className="w-layout-grid grid-2-columns contact-v1">
                        <div>
                            <h1 className="display-1 mg-bottom-12px">Get in touch </h1>
                            <div className="inner-container _310px _100-tablet">
                                <p className="paragraph-small mg-bottom-32px mg-bottom-24px-tablet">
                                    Do you have an questions, suggestions or queries for us, feel free to contact us.
                                </p>
                            </div>
                            <div className="heading-h5-size mg-bottom-18px mg-bottom-12px-tablet">Contact information</div>
                            <div className="card pd-28px---20px">
                                <div className="inner-container _400px">
                                    <div>
                                        <div className="w-layout-grid grid-1-column">
                                            <a href="mailto:sales@only-dev.co.za"
                                               className="card-link-icon-left w-inline-block">
                                                <img src={envelope} alt=""/>
                                                <div>
                                                    <div className="text-200 color-neutral-600 mg-bottom-2px">
                                                        Send us an email
                                                    </div>
                                                    <div className="text-100 bold color-neutral-800">marketing@easyapplications.co.za</div>
                                                </div>
                                            </a>
                                            <a href="tel:+27631490446" className="card-link-icon-left w-inline-block">
                                                <img src={phoneCall} alt=""/>
                                                <div>
                                                    <div className="text-200 color-neutral-600 mg-bottom-2px">
                                                        Give us a call
                                                    </div>
                                                    <div className="text-100 bold color-neutral-800">+27 63 149 0446</div>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card form-wrapper">
                            <div className="width-100 mg-bottom-0 w-form">
                                <form id="wf-form-Contact-Page-Form" onSubmit={sendMessage}>
                                    <div className="w-layout-grid grid-2-columns form">
                                        <div>
                                            <label htmlFor="Name">Full name</label>
                                            <input type="text" className="input small w-input" maxLength="256" onChange={handleChange('fullName')}
                                                   name="Name" value={contactInfo.fullName} placeholder="What’s your name?" id="Name" required/>
                                        </div>
                                        <div>
                                            <label htmlFor="Email">Email address</label>
                                            <input type="email" className="input small w-input" maxLength="256" name="Email"value={contactInfo.email}
                                                   placeholder="What’s your email address?" id="Email" required="" onChange={handleChange('email')}/>
                                        </div>
                                        <div>
                                            <label htmlFor="Phone">Phone number</label>
                                            <input type="tel" className="input small w-input" maxLength="256" name="Phone" value={contactInfo.phoneNumber}
                                                   placeholder="What’s your phone number?" id="Phone" required="" onChange={handleChange('phoneNumber')}/>
                                        </div>
                                        <div>
                                            <label htmlFor="Location">Location</label>
                                            <input type="text" className="input small w-input" maxLength="256" onChange={handleChange('location')}
                                                   name="Location" value={contactInfo.location} placeholder="ex. Johannesburg, SA" id="Location" required=""/>
                                        </div>
                                        <div>
                                            <label htmlFor="Message">Message</label>
                                            <textarea id="Message" name="Message" maxLength="5000" value={contactInfo.message} onChange={handleChange('message')}
                                                      placeholder="Write your message here..." className="text-area w-input"></textarea>
                                        </div>
                                        <div className="contact-btn-wrapper">
                                            <input type="submit" value="Send Message" data-wait="Please wait..." className="btn-primary small black w-button"/>
                                            <div className="social-media-flex">
                                                <a href="https://facebook.com/" target="_blank" className="social-icon-square w-inline-block">
                                                    <div className="social-icon-font"></div>
                                                </a>
                                                <a href="https://twitter.com/" target="_blank" className="social-icon-square w-inline-block">
                                                    <div className="social-icon-font"></div>
                                                </a>
                                                <a href="https://www.instagram.com/" target="_blank" className="social-icon-square w-inline-block">
                                                    <div className="social-icon-font"></div>
                                                </a>
                                                <a href="https://www.linkedin.com/" target="_blank" className="social-icon-square w-inline-block">
                                                    <div className="social-icon-font"></div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section overflow-hidden">
                <div className="container-default w-container">
                    <div className="flex-horizontal mg-bottom-32px">
                        <div className="text-center text-left-mbp">
                            <div className="inner-container _422px center">
                                <h2 className="display-2 mg-bottom-12px">Contact us directly</h2>
                            </div>
                            <div className="inner-container _470px">
                                <p className="paragraph-small mg-bottom-0">
                                    You can drop us an email directly and our consultants will be ready to read your email.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="w-layout-grid grid-3-columns">
                        {contactUs.map((item) => (
                            <a href="mailto:marketing@easyapplications.co.za" className="card link-card-icon-left w-inline-block">
                                <div>
                                    <div className="circle _48px bg-neutral-300">
                                        <img src={item.url} alt=""/>
                                    </div>
                                </div>
                                <div className="flex-vertical">
                                    <h3 className="display-4 mg-bottom-8px">{item.name}</h3>
                                    <div className="inner-container">
                                        <p className="mg-bottom-8px paragraph-small color-neutral-500">{item.description}</p>
                                    </div>
                                    <div className="contact-link v1">
                                        <div>{item.email}</div>
                                        <div className="line-rounded-icon color-accent-1"></div>
                                    </div>
                                </div>
                            </a>
                        ))}
                    </div>
                </div>
            </div>
            {/*<div className="section bg-neutral-200 overflow-hidden">*/}
            {/*    <div className="container-default w-container">*/}
            {/*        <div>*/}
            {/*            <div data-w-id="312c2037-4144-e2ba-31b6-87f8deb9662a" className="text-center mg-bottom-32px">*/}
            {/*                <h2 className="display-2 mg-bottom-12px">Frequently asked questions</h2>*/}
            {/*                <div className="inner-container _480px center">*/}
            {/*                    <p className="paragraph-small mg-bottom-0">*/}
            {/*                        Lorem ipsum dolor sit amet consectetur*/}
            {/*                        adipiscing elit risus metus velit in mattis magna facilisi tempor mattis id dolor*/}
            {/*                        malesuada posuere.*/}
            {/*                    </p>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*            <div data-w-id="312c2037-4144-e2ba-31b6-87f8deb96631" className="inner-container _600px center">*/}
            {/*                <div className="w-layout-grid grid-1-column gap-row-24px">*/}
            {/*                    <div data-w-id="d61267a7-b4ae-4e96-28a7-3024ba7fb0e6"*/}
            {/*                         className="accordion-item-wrapper space-between">*/}
            {/*                        <div className="accordion-content-wrapper width-660px">*/}
            {/*                            <div className="accordion-header">*/}
            {/*                                <h3 className="accordion-title heading-h5-size">How do I post my first*/}
            {/*                                    product?</h3>*/}
            {/*                            </div>*/}
            {/*                            <div className="acordion-body">*/}
            {/*                                <div className="accordion-spacer"></div>*/}
            {/*                                <div className="inner-container _410px _100-tablet">*/}
            {/*                                    <p className="mg-bottom-0 paragraph-small">Lorem ipsum dolor sit amet*/}
            {/*                                        consectetur adipiscing elit risus metus velit in mattis magna*/}
            {/*                                        facilisi tempor mattis id dolor malesuada posuere.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="accordion-icon-wrapper">*/}
            {/*                            <div className="btn-circle-secondary small accordion-btn-circle">*/}
            {/*                                <div className="btn-line horizontal"></div>*/}
            {/*                                <div className="btn-line vertical"></div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div data-w-id="312c2037-4144-e2ba-31b6-87f8deb96641"*/}
            {/*                         className="accordion-item-wrapper space-between">*/}
            {/*                        <div className="accordion-content-wrapper width-660px">*/}
            {/*                            <div className="accordion-header">*/}
            {/*                                <h3 className="accordion-title heading-h5-size">Can I re-post my*/}
            {/*                                    product?</h3>*/}
            {/*                            </div>*/}
            {/*                            <div className="acordion-body">*/}
            {/*                                <div className="accordion-spacer"></div>*/}
            {/*                                <div className="inner-container _410px _100-tablet">*/}
            {/*                                    <p className="mg-bottom-0 paragraph-small">Lorem ipsum dolor sit amet*/}
            {/*                                        consectetur adipiscing elit risus metus velit in mattis magna*/}
            {/*                                        facilisi tempor mattis id dolor malesuada posuere.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="accordion-icon-wrapper">*/}
            {/*                            <div className="btn-circle-secondary small accordion-btn-circle">*/}
            {/*                                <div className="btn-line horizontal"></div>*/}
            {/*                                <div className="btn-line vertical"></div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div data-w-id="312c2037-4144-e2ba-31b6-87f8deb9664f"*/}
            {/*                         className="accordion-item-wrapper space-between">*/}
            {/*                        <div className="accordion-content-wrapper width-660px">*/}
            {/*                            <div className="accordion-header">*/}
            {/*                                <h3 className="accordion-title heading-h5-size">How do I take down my*/}
            {/*                                    product?</h3>*/}
            {/*                            </div>*/}
            {/*                            <div className="acordion-body">*/}
            {/*                                <div className="accordion-spacer"></div>*/}
            {/*                                <div className="inner-container _410px _100-tablet">*/}
            {/*                                    <p className="mg-bottom-0 paragraph-small">Lorem ipsum dolor sit amet*/}
            {/*                                        consectetur adipiscing elit risus metus velit in mattis magna*/}
            {/*                                        facilisi tempor mattis id dolor malesuada posuere.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="accordion-icon-wrapper">*/}
            {/*                            <div className="btn-circle-secondary small accordion-btn-circle">*/}
            {/*                                <div className="btn-line horizontal"></div>*/}
            {/*                                <div className="btn-line vertical"></div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                    <div data-w-id="312c2037-4144-e2ba-31b6-87f8deb9665d"*/}
            {/*                         className="accordion-item-wrapper space-between">*/}
            {/*                        <div className="accordion-content-wrapper width-660px">*/}
            {/*                            <div className="accordion-header">*/}
            {/*                                <h3 className="accordion-title heading-h5-size">How do I feature my product*/}
            {/*                                    after launching?</h3>*/}
            {/*                            </div>*/}
            {/*                            <div className="acordion-body">*/}
            {/*                                <div className="accordion-spacer"></div>*/}
            {/*                                <div className="inner-container _410px _100-tablet">*/}
            {/*                                    <p className="mg-bottom-0 paragraph-small">Lorem ipsum dolor sit amet*/}
            {/*                                        consectetur adipiscing elit risus metus velit in mattis magna*/}
            {/*                                        facilisi tempor mattis id dolor malesuada posuere.</p>*/}
            {/*                                </div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                        <div className="accordion-icon-wrapper">*/}
            {/*                            <div className="btn-circle-secondary small accordion-btn-circle">*/}
            {/*                                <div className="btn-line horizontal"></div>*/}
            {/*                                <div className="btn-line vertical"></div>*/}
            {/*                            </div>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
        </div>
    )
}

export default ContactPage