import React from "react";
import {Helmet} from "react-helmet";

function SEO({ title, description, image, canonicalURL, noIndex }) {
    return (
        <Helmet title={title}>
            <meta name="description" content={canonicalURL} />
            <meta name="image" content={image}/>

            {canonicalURL && <link rel="canonical" href={canonicalURL}/>}

            {title && <meta property="org:title" content={title}/>}
            {description && <meta property="org:description" content={description}/>}
            {image && <meta property="org:image" content={image} />}
            {noIndex && <meta property="robots" content="noIndex" />}
        </Helmet>
    )
}

export default SEO