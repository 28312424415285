export const school = [
    {
        "accreditation_no": "22 SCH01 00981",
        "name": "Oxford Combined College",
        "type": "School (Private)",
        "province": "Gauteng",
        "physical_address": "32-12 Rietfontein Farm Pretoria Road Kempton Park 1619",
        "telephone": "011 0540839",
        "email": "oxfordcombinedcollege@gmail.com",
        "lowestGrade": 1,
        "highestGrade": 12,
        "category": "Co-educational",
        "isBoarding": "No",
        "schoolPhase": "Combined",
        "applicationFee": "",
        "curriculum": "CAPS",
        "bankingDetails": [
            {
                "accountName": "",
                "accountNumber": "1987 476 476",
                "bank": "Nedbank",
                "branch": "Festival Mall",
                "branchCode": "198 742",
                "reference": "Learner Name, Surname, Grade"
            }
        ],
        "schoolFees": [
            {
                fee: '1250',
                grade: 'R - 3'
            },
            {
                fee: '1300',
                grade: '4 - 6'
            },
            {
                fee: '1500',
                grade: '7 - 9'
            },
            {
                fee: '1600',
                grade: '10 - 11'
            },
            {
                fee: '2100',
                grade: '12'
            }
        ],
        "grades": [
            {
                "grade": 1
            },
            {
                "grade": 2
            },
            {
                "grade": 3
            },
            {
                "grade": 4
            },
            {
                "grade": 5
            },
            {
                "grade": 6
            },
            {
                "grade": 7
            },
            {
                "grade": 8
            },
            {
                "grade": 9
            },
            {
                "grade": 10
            },
            {
                "grade": 11
            },
            {
                "grade": 12
            }
        ],
        "requirements": [
            {
                name: "Copy of learner's birth certificate or passport",
                category: "learnerBirthCertificate"
            },
            {
                name: "Copy of parent(s) ID or passport",
                category: "parentId"
            },
            {
                name: "Previous term school report",
                category: "schoolReport"
            },
            {
                name: "Transfer letter",
                category: "transferLetter"
            },
            {
                name: "2 colour passport size photo of learner",
                category: "learnerPhoto"
            },
            {
                name: "Proof of address (No older than 3 months)",
                category: "proofResidence"
            },
            {
                name: "Proof of income (No older than 3 months)",
                category: "proofIncome"
            }
        ],
        "subjects": [
            {
                subject: 'Afrikkans FAL',
                category: 'languages'
            },
            {
                subject: 'English HL',
                category: 'languages'
            },
            {
                subject: 'IsiZulu FAL',
                category: 'languages'
            },
            {
                subject: 'IsiZulu HL',
                category: 'languages'
            },
            {
                subject: 'Sepedi HL',
                category: 'languages'
            },
            {
                subject: 'Life Orientation',
                category: 'common'
            },
            {
                subject: 'Business Studies',
                category: 'commercials'
            },
            {
                subject: 'Geography',
                category: 'common'
            },
            {
                subject: 'Economics',
                category: 'commercials'
            },
            {
                subject: 'Mathematical Literacy',
                category: 'commercials'
            },
            {
                subject: 'Tourism',
                category: 'commercials'
            },
            {
                subject: 'Life Sciences',
                category: 'sciences'
            },
            {
                subject: 'Physical Sciences',
                category: 'sciences'
            },
            {
                subject: 'Mathematics',
                category: 'sciences'
            },
            {
                subject: 'Agricultural Sciences',
                category: 'sciences'
            }
        ]
    },
    {
        "accreditation_no": "21 SCH01 00898 PA",
        "name": "Taal Net Training Institute Kempton Park",
        "type": "School (Private)",
        "province": "Gauteng",
        "physical_address": "70 Kempton Road Kempton Park 1619",
        "telephone": "011 9707111",
        "email": "jmagidi@gmail.com",
        "lowestGrade": 1,
        "highestGrade": 12,
        "category": "Co-educational",
        "isBoarding": "Yes",
        "schoolPhase": "Combined",
        "applicationFee": "",
        "curriculum": "CAPS and Cambridge",
        "bankingDetails": [
            {
                "accountName": "Taal-Net Training Institute",
                "accountNumber": "220 837 546",
                "bank": "Standard Bank",
                "branch": "Greenstone",
                "branchCode": "01 634 248",
                "reference": "Learner Name and Surname"
            }
        ],
        "schoolFees": [
            {
                fee: '1750',
                grade: 'R - 3'
            },
            {
                fee: '1800',
                grade: '4 - 6'
            },
            {
                fee: '2040',
                grade: '7'
            },
            {
                fee: '2100',
                grade: '8'
            },
            {
                fee: '2300',
                grade: '9'
            },
            {
                fee: '2400',
                grade: '10'
            },
            {
                fee: '2600',
                grade: '11'
            },
            {
                fee: '2800',
                grade: '12'
            }
        ],
        "grades": [
            {
                "grade": 1
            },
            {
                "grade": 2
            },
            {
                "grade": 3
            },
            {
                "grade": 4
            },
            {
                "grade": 5
            },
            {
                "grade": 6
            },
            {
                "grade": 7
            },
            {
                "grade": 8
            },
            {
                "grade": 9
            },
            {
                "grade": 10
            },
            {
                "grade": 11
            },
            {
                "grade": 12
            }
        ],
        "requirements": [
            {
                name: "2 certified copies of learner's birth certificate or passport",
                category: "learnerBirthCertificate"
            },
            {
                name: "Copies of parent(s) ID or passport",
                category: "parentId"
            },
            {
                name: "Previous school report",
                category: "schoolReport"
            },
            {
                name: "Proof of residence",
                category: "proofResidence"
            },
            {
                name: "Transfer letter",
                category: "transferLetter"
            },
            {
                name: "3 months bank statement",
                category: "bankStatement"
            },
            {
                name: "Payslip",
                category: "proofIncome"
            },
            {
                name: "ID/Passport photo of learner",
                category: "learnerPhoto"
            }
        ]
    },
    {
        "accreditation_no": "19 SCH01 00784",
        "name": "New Millenium College",
        "type": "School (Private)",
        "province": "Gauteng",
        "physical_address": "32 - 11 Pretoria Road Rietfontein Farm Birchleigh Kempton Park 1618",
        "telephone": "011 042 7413",
        "email": "newmilleniumcollege@rocketmail.com",
        "lowestGrade": 1,
        "highestGrade": 12,
        "category": "Co-educational",
        "isBoarding": "No",
        "schoolPhase": "Combined",
        "applicationFee": "",
        "curriculum": "CAPS",
        "bankingDetails": [
            {
                "accountName": "New Millenium College",
                "accountNumber": "1001 378 903",
                "bank": "Nedbank",
                "branch": "Festive Mall",
                "branchCode": "198 742",
                "reference": "Learner Name, Surname, Grade"
            }
        ],
        "schoolFees": [
            {
                fee: '1540',
                grade: 'R - 3'
            },
            {
                fee: '1600',
                grade: '4 - 6'
            },
            {
                fee: '1850',
                grade: '7 - 9'
            },
            {
                fee: '1980',
                grade: '10 - 11'
            },
            {
                fee: '2560',
                grade: '12'
            }
        ],
        "grades": [
            {
                "grade": 1
            },
            {
                "grade": 2
            },
            {
                "grade": 3
            },
            {
                "grade": 4
            },
            {
                "grade": 5
            },
            {
                "grade": 6
            },
            {
                "grade": 7
            },
            {
                "grade": 8
            },
            {
                "grade": 9
            },
            {
                "grade": 10
            },
            {
                "grade": 11
            },
            {
                "grade": 12
            }
        ],
        "requirements": [
            {
                name: "Copies of learner's last two (2) years report card",
                category: "schoolReport"
            },
            {
                name: "Learner's ID/Passport copy",
                category: "learnerBirthCertificate"
            },
            {
                name: "2 passport size photos of learner",
                category: "learnerPhoto"
            },
            {
                name: "Parent(s) ID/Passport copy",
                category: "parentId"
            }
        ],
        "subjects": [
            {
                "languages": [
                    {
                        "subject": "Afrikaans FAL"
                    },
                    {
                        "subject": "English HL"
                    },
                    {
                        "subject": "IsiZulu HL"
                    },
                    {
                        "subject": "IsiZulu FAL"
                    },
                    {
                        "subject": "Sepedi HL"
                    }
                ]
            },
            {
                "commercials": [
                    {
                        "subject": "Life Orientation"
                    },
                    {
                        "subject": "Geography"
                    },
                    {
                        "subject": "Business Studies"
                    },
                    {
                        "subject": "Economics"
                    },
                    {
                        "subject": "Mathematical Literacy"
                    },
                    {
                        "subject": "Tourism"
                    }
                ]
            },
            {
                "sciences": [
                    {
                        "subject": "Life Orientation"
                    },
                    {
                        "subject": "Geography"
                    },
                    {
                        "subject": "Life Sciences"
                    },
                    {
                        "subject": "Physical Sciences"
                    },
                    {
                        "subject": "Mathematics"
                    },
                    {
                        "subject": "Agricultural Science"
                    }
                ]
            }
        ]
    },
]