import React from "react";

function ComingSoonPage() {
    return (
        <div className="section coming-soon-section">
            <div className="container-default z-index-1 width-100 w-container">
                <div className="w-layout-grid grid-2-columns cta-v4">
                    <div data-w-id="375d27bb-3f5b-49a1-2ec8-34bff2b75900" style={{transform: `translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`,
                             opacity: 1, transformStyle: "preserve-3d"}} className="card full-section-page-card">
                        <div className="inner-container _446px _100-tablet">
                            <h1 className="display-1 mg-bottom-12px">
                                We’re preparing something great!
                            </h1>
                            <p className="paragraph-small mg-bottom-24px">
                                Allow prospective parents to apply for enrollment through WhatsApp or add an AI Chatbot to your
                                website to make filling an application form user friendly and interactive.
                            </p>
                            <div className="inner-container _394px">
                                <div className="mg-bottom-0 w-form">
                                    <form id="wf-form-Coming-Soon-Form" name="wf-form-Coming-Soon-Form">
                                        <div className="position-relative">
                                            <input type="email" className="input small subscribe-btn-inside w-input"
                                                   placeholder="Enter your email address" id="Newsletter-2" required="" />
                                            <div className="btn-inside-input-wrapper">
                                                <input type="submit" value="Join Waitlist" data-wait="Please wait..."
                                                       className="btn-primary small w-button" />
                                            </div>
                                        </div>
                                    </form>
                                    <div className="success-message w-form-done" aria-label="Coming Soon Form success">
                                        <div>Thanks for subscribing to our newsletter</div>
                                    </div>
                                    <div className="error-message w-form-fail" aria-label="Coming Soon Form failure">
                                        <div>Oops! Something went wrong while submitting the form.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="w-node-_0a24464e-b5d5-2cb2-77a1-ee07a8037ea7-8c030b1a" style={{opacity: 1}} className="position-relative flex-horizontal">
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c04c950f2c78b1685c1894_envelope-aggregator-x-webflow-template.svg"
                            alt="" className="envelope-image" />
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c04b73560a1760566b943c_triangle-shape-aggregator-x-webflow-template.svg"
                            alt="" className="floating-item shape-v7"
                            style={{opacity: 1, transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`, transformStyle: "preserve-3d"}}
                             />
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c04cf20f2c7875f15c1cc4_small-circle-shape-aggregator-x-webflow-template.svg"
                            alt="" style={{opacity: 1}} className="floating-item shape-v8" />
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c04d6740b7efcd007c5d55_circle-shape-aggregator-x-webflow-template.svg"
                            alt="" style={{opacity: 1}} className="floating-item shape-v9" />
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c0657d7ee8205f7197ea24_curvy-line-aggregator-x-webflow-template.svg"
                            alt="" style={{opacity: 1}} className="floating-item shape-v11" />
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c06660be56c3b210b1e3d6_small-curvy-line-aggregator-x-webflow-template.svg"
                            alt="" className="floating-item shape-v12"
                            style={{opacity: 1, transform: `translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)`, transformStyle: "preserve-3d"}}
                              />
                        <img src="https://assets-global.website-files.com/63bed0273cfe5e3f80742329/63c064b6ac68b119a997ca7a_rhombus-aggregator-x-webflow-template.svg"
                            alt="" style={{opacity: 1}} className="floating-item shape-v10" />
                    </div>
                </div>
            </div>
            <div className="floating-item circle-floating v2"></div>
        </div>
    )
}

export default ComingSoonPage