import React from "react";

function Logo() {
    return (
        <a href="/" className="header-logo-link left w-nav-brand w--current" style={{ marginTop: "14px"}}>
            <h3>Narow (Pty) Ltd.</h3>
            <p className="text-uppercase" style={{ fontSize: '7px', marginTop: '-16px' }}>
                Electronic Admission System for Schools
            </p>
        </a>
    )
}

export default Logo