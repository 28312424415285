import React, {useState} from "react";
import ViewRequirements from "./ViewRequirements";
import featuredStar from "../img/featured-star-icon.svg"

function Schools({ schoolName, physicalAddress, schoolPhase }) {
    const [open, setOpen] = useState(false)

    const showDialog = () => {
        setOpen(true)
    }

    return (
        <div role="listitem" className="w-dyn-item">
            <div className="card product-card-v1">
                <div className="product---description">
                    {/*<div>*/}
                    {/*    <img*/}
                    {/*        src="https://assets.website-files.com/63bed0273cfe5e611e742359/63c82ff49968dc3995625185_techmesh-featured-image-aggregator-x-webflow-template.svg"*/}
                    {/*        alt="Techmesh" className="avatar-circle _04"/>*/}
                    {/*</div>*/}
                    <div>
                        <div className="product---name">{schoolName}</div>
                        <div>
                            <p className="paragraph-small medium mg-bottom-10px">
                                {physicalAddress}
                            </p>
                        </div>
                        <div className="product---tag-wrapper">
                            <div
                                className="product---featured-icon hidden w-condition-invisible">
                                <img src={featuredStar} alt="" className="_w-h-26px"/>
                            </div>
                            <div className="badge-primary small">{schoolPhase}</div>
                        </div>
                    </div>
                </div>
                <div className="product-card---links-wrapper">
                    <a href="https://apply.easyapplications.co.za" className="btn-secondary small" onClick={showDialog}>
                        Learn More
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Schools